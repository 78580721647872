import React from 'react';

import '../css/CustomKeyboard.css';
import { isAndroid } from 'react-device-detect'

const CustomKeyboard = ({ onClick,isIOS }) => {
    const alphabet_row1 = 'qwertyuiop'.split('');
    const alphabet_row2 = 'asdfghjkl'.split('');
    const alphabet_row3 = 'zxcvbnm'.split('');
   

    return (
        <div className='keyboard-container'>
            <div className='container'>

            <div className={isAndroid ?"custom-keyboard":"custom-ios-keyboard"}>
                {alphabet_row1.map(letter => (
                    <button key={letter} onClick={() => onClick(letter)}>{letter}</button>
                ))}
            </div>
            <div className={isAndroid ?"custom-keyboard2":"custom-ios-keyboard2"}>
                {alphabet_row2.map(letter => (
                    <button key={letter} onClick={() => onClick(letter)}>{letter}</button>
                ))}
            </div>
            <div className={isAndroid ?"custom-keyboard3":"custom-ios-keyboard3"}>
                {alphabet_row3.map(letter => (
                    <button key={letter} onClick={() => onClick(letter)}>{letter}</button>
                ))}
            </div>
            </div>
        </div>
    );
}

export default CustomKeyboard;
