import React from 'react';
import '../css/Word.css'; 
import { isAndroid, isIOS } from 'react-device-detect';

const Word = ({ selectedWord, correctLetters }) => {
  return (
    <div className={isAndroid? "android-word":isIOS? "ios-word":"word"}>
      {selectedWord.split('').map((letter, i) => {
        return (
          <span className="letter" key={i}>
            {correctLetters.includes(letter) ? letter : ''}
          </span>
        )
        
      })}
    </div>
  )
 
}

export default Word
