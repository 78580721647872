// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";
import { getFirestore } from '@firebase/firestore';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}; 


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
let analytics;
try {
  analytics = getAnalytics(app);
  logEvent(analytics,'app_open',{name:'category_selection'});
  console.log("here");
} catch (e) {
  console.error(e);
}

export {db,analytics};