import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Figure from './components/Figure';
import WrongLetters from './components/WrongLetters';
import Word from './components/Word';
import Popup from './components/Popup';
import Notification from './components/Notification';
import { showNotification as show } from './helpers/helpers';
import SplashScreen from './SplashScreen';
import random from '../src/wordlist/random.json';
import animals from '../src/wordlist/animals.json';
import fruits from '../src/wordlist/fruits.json';
import backgroundImage2 from '../src/background.jpg';
// import packageJson from '../package.json';
import './App.css';
import CustomKeyboard from './components/CustomKeyboard';
import CategoryModal from './components/CategoryModal';
import { isAndroid, isIOS } from 'react-device-detect';
import { onSnapshot, collection, query, orderBy, limit,addDoc} from 'firebase/firestore';
import { db,analytics } from './firebase';
import HighscorePage from './components/Highscores';

import FeedbackModal from './components/FeedbackModal';



const randomList = random.words;
const fruitList = fruits.words;
const animalList = animals.words;
let words = randomList;
let selectedWord = words[Math.floor(Math.random() * words.length)];

// const version = packageJson.version;

function App() {

  const [loading, setLoading] = useState(true);
  const [chance, setChance] = useState(6);
  const [score, setScore] = useState(0);
  const [playable, setPlayable] = useState(true);
  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showHighScores, setShowHighScores] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showHighScorepage, setShowHighScorepage] = useState(false);
  const collectionRef = collection(db, 'highscores');

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');

  const [leaderboard, setLeaderboard] = useState([]);

  const backgroundImage = backgroundImage2;

  useEffect(() => {
    if (isIOS) {
      document.addEventListener('touchmove', function(event) {
        event.preventDefault();
      }, { passive: false });

      document.addEventListener('gesturestart', function(event) {
        event.preventDefault();
      });
    }
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--background-image', `url(${backgroundImage})`);
  }, [loading, backgroundImage]);

  useEffect(() => {
    const handleKeydown = (event) => {
      const { key, keyCode } = event;
      if (playable && keyCode >= 65 && keyCode <= 90) {
        const letter = key.toLowerCase();
        if (selectedWord.includes(letter)) {
          if (!correctLetters.includes(letter)) {
            setCorrectLetters((currentLetters) => [...currentLetters, letter]);
          } else {
            show(setShowNotification);
          }
        } else {
          if (!wrongLetters.includes(letter)) {
            setWrongLetters((currentLetters) => [...currentLetters, letter]);
            setChance((prevChance) => prevChance - 1);
          } else {
            show(setShowNotification);
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => window.removeEventListener('keydown', handleKeydown);
  }, [correctLetters, wrongLetters, playable]);


  function handleKeyboardClick(letter) {
    if (playable) {
      if (selectedWord.includes(letter)) {
        if (!correctLetters.includes(letter)) {
          setCorrectLetters((currentLetters) => [...currentLetters, letter]);
        } else {
          show(setShowNotification);
        }
      } else {
        if (!wrongLetters.includes(letter)) {
          setWrongLetters((currentLetters) => [...currentLetters, letter]);
          setChance((prevChance) => prevChance - 1);
        } else {
          show(setShowNotification);
        }
      }
    }
  }

  function playAgain() {
    setPlayable(true);
    if (wrongLetters.length === 6 || chance === 0) {
      setScore(0);
      setChance(6);
      setCorrectLetters([]);
      setWrongLetters([]);
      setShowCategoryModal(true);
      setSelectedCategory(null);
    }
    else {
      setScore(score => score + 1);
      if (chance > 0 && chance < 6) setChance(chance => chance + 1);
    }
    setCorrectLetters([]);
    setWrongLetters([]);
    const random = Math.floor(Math.random() * words.length);
    selectedWord = words[random];

  }


  useEffect(() => {
    if (chance === 0) {
      setPlayable(false);

      setShowHighScores(true);
    }
  }, [chance]);


  const onSelectCategory = (category) => {
    setSelectedCategory(category);
    setShowCategoryModal(false);
    if (category === 'animals') words = animalList;
    else if (category === 'fruits') words = fruitList;
    else words = randomList;
    const random = Math.floor(Math.random() * words.length);
    selectedWord = words[random];

  }


  useEffect(() => {
    if (collectionRef) {
      const q = query(collectionRef, orderBy('Score', 'desc'), limit(10));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setLeaderboard(items);
      }, (error) => {
        console.error("Error fetching data:", error);
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);



  const handleShowHighScorePage = () => {
    if (!showHighScorepage) setShowHighScorepage(true);
    if (!showCategoryModal) setShowCategoryModal(false);
    if (showHighScorepage) setShowHighScorepage(false);
    if (showCategoryModal) setShowCategoryModal(true);
  };

  const openFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  // Function to handle closing the feedback modal
  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const submitFeedback = async() => {
      try {
        await addDoc(collection(db, 'feedback'), {
          Comment: feedbackText,
        });
      } catch (error) {
        console.error('Error adding document: ', error);
      }
   
    setFeedbackText('');
    alert('Thank you for your feedback! Much appreciated :)');
    closeFeedbackModal();
  };


  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <Header />
          {!showHighScorepage &&
            <div className={isAndroid? "android-top-bar":isIOS? "ios-top-bar":"top-bar"}>
              <button disabled={true} className={isAndroid? "android-score-button":isIOS? "ios-score-button":"score-button"}>Score &nbsp;&nbsp; {score}</button>
              <button className={isAndroid? "android-high-score-button":isIOS? "ios-high-score-button":"high-score-button"} onClick={handleShowHighScorePage}>High Scores</button>
              <button className={isAndroid? "android-feedback-button":isIOS? "ios-feedback-button":"feedback-button"} onClick={openFeedbackModal}>Feedback</button>
              {/* Feedback modal */}
              <FeedbackModal
                show={showFeedbackModal}
                onClose={closeFeedbackModal}
                onSubmit={submitFeedback}
                feedbackText={feedbackText}
                setFeedbackText={setFeedbackText}
              />
            </div>}
          {showHighScorepage && collectionRef && <div className="popup-overlay">
            <div className='highscore-popup'>
              <HighscorePage highscores={leaderboard} />
              <button className="close-button" onClick={handleShowHighScorePage}>
                Back
              </button>
            </div>
          </div>}
          {!showHighScorepage && showCategoryModal && !selectedCategory && <CategoryModal onSelectCategory={onSelectCategory} />}
          {window.innerWidth >= 600 && selectedCategory && (!showCategoryModal && !showHighScorepage) && (
            <>
              <div className="game-container">
                <Figure wrongLetters={chance} />
                <WrongLetters wrongLetters={wrongLetters} chance={chance} />
                <Word selectedWord={selectedWord} correctLetters={correctLetters} />
              </div>
              <Popup correctLetters={correctLetters} wrongLetters={wrongLetters} chance={chance} selectedWord={selectedWord} setPlayable={setPlayable} playAgain={playAgain} score={score} />
              <Notification showNotification={showNotification} />

            </>
          )}
          {isAndroid && window.innerWidth < 450 && selectedCategory && (!showCategoryModal && !showHighScorepage) && (
            <>
              {/* <NonMobile /> */}
              <div className="android-game-container">
                <Figure wrongLetters={chance} />
                <WrongLetters wrongLetters={wrongLetters} chance={chance} />
                <Word selectedWord={selectedWord} correctLetters={correctLetters} />
              </div>
              <CustomKeyboard onClick={handleKeyboardClick} />
              <Popup correctLetters={correctLetters} wrongLetters={wrongLetters} chance={chance} selectedWord={selectedWord} setPlayable={setPlayable} playAgain={playAgain} score={score} />
              <Notification showNotification={showNotification} />
            </>
          )}
          {isIOS && window.innerWidth < 450 && selectedCategory && (!showCategoryModal && !showHighScorepage) && (
            <>
              {/* <NonMobile /> */}
              <div className="ios-game-container">
                <Figure wrongLetters={chance} />
                <WrongLetters wrongLetters={wrongLetters} chance={chance} />
                <Word selectedWord={selectedWord} correctLetters={correctLetters} />
              </div>
              <CustomKeyboard onClick={handleKeyboardClick} isIOS={isIOS} />
              <Popup correctLetters={correctLetters} wrongLetters={wrongLetters} chance={chance} selectedWord={selectedWord} setPlayable={setPlayable} playAgain={playAgain} score={score} />
              <Notification showNotification={showNotification} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default App;