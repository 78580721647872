import React from 'react';
import '../css/Header.css'; // Import CSS file for styling

import HangSnake from '../gamelogo.png';
import { isAndroid,isIOS} from 'react-device-detect';

const Header = () => {
  return (
    
    <div className={isAndroid? "android-header-container":isIOS? "ios-header-container":"header-container"}>
      <img src={HangSnake} alt="Hang Snake" className={isAndroid? "android-logo-image":isIOS? "ios-logo-image":"logo-image"} />
      <div className="header-text">
        <p className="header-message">Let's uncover the hidden word.<br />
        {/* <br />Type a Letter and avoid getting any incorrect. */}
        </p>
      </div>
    </div>
  );
}

export default Header;
