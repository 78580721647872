import React from 'react'
import '../css/Notification.css'; // Import CSS file for styling

const Notification = ({ showNotification }) => {
  return (
    <div className={`notification-container ${showNotification ? 'show' : ''}`}>
      <p style={{ fontWeight: 'bold', fontSize: '16px' }}>You have already entered this letter</p>
    </div>
  )
}

export default Notification
