import React from 'react';
import '../css/FeedbackModal.css'; 

const FeedbackModal = ({ show, onClose, onSubmit, feedbackText, setFeedbackText }) => {
  if (!show) return null;

  return (
    <div className="modal-background">
      <div className="modal-content">
        <h2>Feedback</h2>
        {/* <span className="close" onClick={onClose}>
          &times;
        </span> */}
        <textarea
          rows="4"
          cols="50"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Tell us what you think of the app..."
        ></textarea>
        <button className="submit-button" onClick={onSubmit}>Submit</button>
        <button className="cancel-button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default FeedbackModal;
