import React from 'react';
import './SplashScreen.css'; // Import your CSS file for styling
import imageSrc from '../src/HangSnake-4-7-2024-unscreen.gif';
import checkSrc from '../src/checked.png';

const SplashScreen = () => {
  return (
    <div>
      <div className="text-container">
        <img src={imageSrc} alt="Logo" className="splash-image" /> 
        <div className="centered-text">
          <p className="text1">Embark on a word-hunting adventure</p>
          <p className="text1">that blends the excitement of</p>
          <p className="text1">Hangman with the twists of Snake.</p>
        </div>
        <div className="checkbox-container">
          <div className="checkbox-content">
            <img src={checkSrc} alt="check" className="check-image" />
            <p className='text2'>Uncover the hidden word</p>
          </div>
          <div className="checkbox-content">
            <img src={checkSrc} alt="check" className="check-image" />
            <p className='text2'>Avoid obstacles</p>
          </div>
          <div className="checkbox-content">
            <img src={checkSrc} alt="check" className="check-image" />
            <p className='text2'>Level up your score!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
