import React, { useEffect,useState} from 'react';
import { checkWin } from '../helpers/helpers';
import '../css/Popup.css'; 
import { collection,addDoc} from 'firebase/firestore';
import {db} from '../firebase';

const Popup = ({correctLetters, wrongLetters, selectedWord, setPlayable, playAgain , chance, score}) => {
  let finalMessage = '';
  let finalMessageRevealWord = '';
  let playable = true;
  const [name, setName] = useState('');

  if( checkWin(correctLetters, wrongLetters, selectedWord, chance) === 'win' ) {
    finalMessage = 'Congratulations! You solved it, get ready for your next word!';
    playable = false;
  } else if( checkWin(correctLetters, wrongLetters, selectedWord, chance) === 'lose' ) {
    finalMessage = 'Noooooooo!!! You lost';
    finalMessageRevealWord = `The word was: ${selectedWord}`;
    playable = false;
  }

  useEffect(() => {
    setPlayable(playable);
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, 'highscores'), {
        Name: name,
        Score: score,
        Time: Date.now(), // You might want to adjust this based on your game logic
      });
      // Reset the game
      playAgain();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="popup-container" style={finalMessage !== '' ? {display:'flex'} : {}}>
      <div className="popup">
        <h2>{finalMessage}</h2>
        <h3>{finalMessageRevealWord}</h3>
       {chance!==0 && <button className="popup-button" onClick={playAgain}>Let's Go!</button>}
       {chance===0 && score <2 &&<button className="popup-button" onClick={playAgain}>Let's Go!</button>}
       { chance ===0 && score >1 &&
       <div><p>Please enter your name to submit your score:</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            maxlength="20" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
          <button type="submit">Submit</button>
          </form>
          </div>
          }
      </div>
    </div>
  )
}

export default Popup
