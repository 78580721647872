import React from 'react';
import '../css/WrongLetters.css'; 
import { isAndroid, isIOS } from 'react-device-detect';

const WrongLetters = ({ wrongLetters , chance }) => {
  return (
    <div className={isAndroid? "android-wrong-letters-container":isIOS? "ios-wrong-letters-container":"wrong-letters-container"}>
      <div>
        <p>Chances Left&nbsp;&nbsp;&nbsp;{chance} <br/><br/>
          Incorrect Letters</p> 
        {wrongLetters
          .map((letter, i) => <span key={i}>{letter}</span>)
          .reduce((prev, curr) => prev === null ? [curr] : [prev, ', ', curr], null)}
      </div>
    </div>
  )
}

export default WrongLetters
