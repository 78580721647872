import React, { useState } from 'react';
import '../css/CategoryModal.css';
import { isAndroid, isIOS } from 'react-device-detect';

const CategoryModal = ({ onSelectCategory }) => {
  const [category, setCategory] = useState('');

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSelectCategory = () => {
    if (category.trim() !== '') {
      onSelectCategory(category.trim());
    } else {
      // Handle case when no category is selected
      alert('Please select a category.');
    }
  };

  return (
    // <div className={isAndroid? "android-modal":isIOS? "ios-modal":"modal"}>
    <div className="modal">
      <h2>Select a Category</h2>
      <select value={category} onChange={handleCategoryChange}>
        <option value="">-- Please Select --</option>
        <option value="animals">Animals</option>
        <option value="fruits">Fruits</option>
        <option value="random">Random</option>
        {/* Add more categories as needed */}
      </select>
      <button onClick={handleSelectCategory}>Start Game</button>
    </div>
  );
};

export default CategoryModal;

