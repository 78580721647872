
import React from 'react';
import HangSnake from '../gamelogo.png';
import '../css/HighscorePage.css'; 

const HighscorePage = ({ highscores }) => {
    if (!highscores) {
        return <div>Loading...</div>;
      }
  return (
    <div>
    <div className="logo">
      <img src={HangSnake} alt="Hang Snake" className='highscore-logo-image' />
        <h1 style={{fontSize:'38px', fontWeight:'bold'}}>High Scores</h1>
      </div>
    <div className="highscore-page">
      <div className="highscore-list">
        <ul>
          {highscores.map((score, index) => (
            <li key={index}>
              <p >{score.Name}</p>
              <p >{score.Score}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </div>
  );
}

export default HighscorePage;