import React from 'react';
import '../css/Figure.css';
import { isAndroid, isIOS } from 'react-device-detect';

const Figure = ({ wrongLetters }) => {
  const errors = wrongLetters;
  return (
    <>
      {isAndroid || isIOS ? (
       <svg viewBox="0 0 200 250" className={isAndroid? "android-figure-container":isIOS? "ios-figure-container":"figure-container"} style={{ width: '35%' }}>
       {/* <!-- Rod --> */}
       <line x1="60" y1="20" x2="140" y2="20" />
       <line x1="140" y1="20" x2="140" y2="50" />
       <line x1="60" y1="20" x2="60" y2="230" />
       <line x1="20" y1="230" x2="100" y2="230" />
     
       {/* <!-- Head --> */}
       {errors > 0 &&
         <circle cx="140" cy="70" r="20" />
       }
       {/* <!-- Body --> */}
       {errors > 1 &&
         <line x1="140" y1="90" x2="140" y2="150" />
       }
       {/* <!-- Arms --> */}
       {errors > 2 &&
         <line x1="140" y1="120" x2="120" y2="100" />
       }
       {errors > 3 &&
         <line x1="140" y1="120" x2="160" y2="100" />
       }
       {/* <!-- Legs --> */}
       {errors > 4 &&
         <line x1="140" y1="150" x2="120" y2="180" />
       }
       {errors > 5 &&
         <line x1="140" y1="150" x2="160" y2="180" />
       }
     </svg>
     
      ) : (
        <svg height="187.5" width="150" className="figure-container">
          {/* <!-- Rod --> */}
          <line x1="45" y1="15" x2="105" y2="15" />
          <line x1="105" y1="15" x2="105" y2="37.5" />
          <line x1="45" y1="15" x2="45" y2="172.5" />
          <line x1="15" y1="172.5" x2="75" y2="172.5" />
  
          {/* <!-- Head --> */}
          {6 - errors > 0 && <circle cx="105" cy="52.5" r="15" />}
          {/* <!-- Body --> */}
          {6 - errors > 1 && <line x1="105" y1="67.5" x2="105" y2="112.5" />}
          {/* <!-- Arms --> */}
          {6 - errors > 2 && <line x1="105" y1="82.5" x2="90" y2="75" />}
          {6 - errors > 3 && <line x1="105" y1="82.5" x2="120" y2="75" />}
          {/* <!-- Legs --> */}
          {6 - errors > 4 && <line x1="105" y1="112.5" x2="90" y2="135" />}
          {6 - errors > 5 && <line x1="105" y1="112.5" x2="120" y2="135" />}
        </svg>
      )}
    </>
  );
}  

export default Figure;
